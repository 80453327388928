<template>
<div>
<p>
    魔改自&emsp;&emsp;<a href="http://kancolle-calc.net/suffering.html">被害状況簡易計算</a>
</p>
<p>
    攻撃力&emsp;&emsp;<el-input v-model="atk" placeholder="请输入敌舰攻击力" style="width: 200px" size="small"></el-input><br>
</p>
<p>
    装甲&emsp;&emsp;&emsp;<el-input v-model="armor" placeholder="请输入我方舰娘装甲" style="width: 200px" size="small"></el-input><br>
    最大耐久&emsp;<el-input v-model="maxhp" placeholder="请输入我方舰娘最大耐久" style="width: 200px" size="small"></el-input><br>
    現在耐久&emsp;<el-input v-model="hp" placeholder="请输入我方舰娘当前耐久" style="width: 200px" size="small"></el-input><br>
</p>

<el-button @click="calcHard" size="small">計算</el-button>

<p></p>

<div v-for="(value,key,index) in results" :key="index">
    <el-divider content-position="left" style="max-width:1000px">敌舰: {{key}}</el-divider>
    <el-table :data="value" style="max-width:1000px">
        <el-table-column label="我方被害状况" prop="状态">
        </el-table-column>
        <el-table-column label="T有利" prop="T有利">
        </el-table-column>
        <el-table-column label="同航" prop="同航">
        </el-table-column>
        <el-table-column label="返航" prop="返航">
        </el-table-column>
        <el-table-column label="T不利" prop="T不利">
        </el-table-column>
        <el-table-column label="综合（无彩云）" prop="综合（无彩云）">
        </el-table-column>
        <el-table-column label="综合（有彩云）" prop="综合（有彩云）">
        </el-table-column>
    </el-table>
    <br>
</div>
</div>
</template>

<script>
export default {
    data() {
        return {
            atk: 250,
            armor: 100,
            maxhp: 120,
            hp: 120,
            results: {}
        }
    },
    methods: {
        calcHard() {
            var _atk = parseInt(this.atk)
            var armor = parseInt(this.armor)
            var hp = parseInt(this.hp)
            var maxhp = parseInt(this.maxhp)

            var status_atk = new Array()
            status_atk["小破以下"] = 1
            status_atk["中破（雷击）"] = 0.8
            status_atk["中破（炮击）"] = 0.7

            var form_atk = new Array()
            form_atk["T有利"] = 1.2
            form_atk["同航"] = 1
            form_atk["返航"] = 0.8
            form_atk["T不利"] = 0.6

            var form_chance = new Array()
            form_chance["T有利"] = 0.15
            form_chance["同航"] = 0.45
            form_chance["返航"] = 0.3
            form_chance["T不利"] = 0.1

            var form_chance_saiun = new Array()
            form_chance_saiun["T有利"] = 0.15
            form_chance_saiun["同航"] = 0.45
            form_chance_saiun["返航"] = 0.4
            form_chance_saiun["T不利"] = 0.0

            this.result = ""

            for (var status in status_atk) {

                var summary = new Array()
                var summary_saiun = new Array()

                summary['小破以下'] = 0
                summary_saiun['小破以下'] = 0
                summary['中破'] = 0
                summary_saiun['中破'] = 0
                summary['大破'] = 0
                summary_saiun['大破'] = 0

                this.results[status] = []
                var tmp_dict = {}
                tmp_dict["小破以下"] = {}
                tmp_dict["中破"] = {}
                tmp_dict["大破"] = {}

                for (var key in form_atk) {

                    var atk = _atk * form_atk[key] * status_atk[status]
                    var c = this.calc(atk, armor, hp)
                    var damages = c.damages
                    var total = c.total

                    var keys = Object.keys(damages)
                    keys.sort()

                    var safe = 0
                    var halfBroken = 0
                    var fullBroken = 0

                    for (var j in keys) {
                        var dmg = keys[j]
                        if ((hp - dmg) * 2 > maxhp) {
                            safe += damages[dmg]
                        } else if ((hp - dmg) * 4 > maxhp) {
                            halfBroken += damages[dmg]
                        } else {
                            fullBroken += damages[dmg]
                        }
                    }

                    tmp_dict["小破以下"][key] = (safe * 100.0 / total).toFixed(2)
                    tmp_dict["中破"][key] = (halfBroken * 100.0 / total).toFixed(2)
                    tmp_dict["大破"][key] = (fullBroken * 100.0 / total).toFixed(2)
                    
                    //$('#result').printf('小破以下 %.2f%%<br>', safe * 100.0 / total)
                    //$('#result').printf('中破 %.2f%%<br>', halfBroken * 100.0 / total)
                    //$('#result').printf('大破 %.2f%%<br><br>', fullBroken * 100.0 / total)

                    summary['小破以下'] += safe * 100.0 / total * form_chance[key]
                    summary_saiun['小破以下'] += safe * 100.0 / total * form_chance_saiun[key]
                    summary['中破'] += halfBroken * 100.0 / total * form_chance[key]
                    summary_saiun['中破'] += halfBroken * 100.0 / total * form_chance_saiun[key]
                    summary['大破'] += fullBroken * 100.0 / total * form_chance[key]
                    summary_saiun['大破'] += fullBroken * 100.0 / total * form_chance_saiun[key]
                }
                
                tmp_dict["小破以下"]["综合（无彩云）"] = summary['小破以下'].toFixed(2)
                tmp_dict["中破"]["综合（无彩云）"] = summary['中破'].toFixed(2)
                tmp_dict["大破"]["综合（无彩云）"] = summary['大破'].toFixed(2)

                tmp_dict["小破以下"]["综合（有彩云）"] = summary_saiun['小破以下'].toFixed(2)
                tmp_dict["中破"]["综合（有彩云）"] = summary_saiun['中破'].toFixed(2)
                tmp_dict["大破"]["综合（有彩云）"] = summary_saiun['大破'].toFixed(2)

                for(var tmp_status in tmp_dict) {
                    var record = tmp_dict[tmp_status]
                    record["状态"] = tmp_status
                    this.results[status].push(record)
                }
            }
        },

        calc(atk, armor, hp) {
            var damages = {}
            var total = 0

            for (var j = 0; j < armor - 1; j++) {
                var A = 0.7 * armor + j * 0.6
                var dmg = Math.floor(atk - A)
                if (dmg >= hp) {
                    var stoppers = this.applyStopper(hp)
                    for (var k in stoppers) {
                        if (typeof(damages[stoppers[k]]) === 'undefined') {
                            damages[stoppers[k]] = 1.0 / stoppers.length
                        } else {
                            damages[stoppers[k]] += 1.0 / stoppers.length
                        }
                    }
                } else {
                    if (typeof(damages[dmg]) === 'undefined') {
                        damages[dmg] = 1.0
                    } else {
                        damages[dmg] += 1.0
                    }
                }
                total += 1
            }
            return {total: total, damages: damages}
        },

        applyStopper(hp) {
            var ret = []
            for (var j = 0; j <= hp - 1; j += 1) {
                var dmg = Math.floor(hp * 0.5 + j * 0.3)
                ret.push(dmg)
            }
            return ret
        }
    }
}
</script>

<style scope>
#result {
    white-space: pre-wrap;
}
</style>